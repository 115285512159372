type TableBodyProps = {
  value?: string;
};
export function TableValue(props: TableBodyProps) {
  const { value } = props;
  return (
    <>
      <div className="flex-1 text-white text-tiny md:text-lg">{value}</div>
    </>
  );
}
