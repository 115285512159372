import { ChangeEvent, useState } from "react";
import { uploadDocument } from "../config/save.firebase.document";
import { Student } from "../interfaces/students";
import logo from "../assets/logo.png";

const DEFAULT_STUDENT = {
  barcode: "",
  degree_number: "",
  reg_number: "",
  name: "",
  last_name: "",
  cnic: "",
  discipline: "",
  college: "",
  enrollment_date: "",
  completion_date: "",
  transcript_number: "",
  last_degree: "",
  last_degree_reg_num: "",
  address: "",
  contact: "",
  email: "",
  examination: "",
  passing_year: "",
  session: "",
  total_marks_gpa: "",
  obtained_marks_gpa: "",
};
export function AddRecord() {
  const [student, setStudent] = useState<Student>(DEFAULT_STUDENT);
  const [saving, setSaving] = useState<boolean | undefined>(undefined);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStudent({ ...student, [name]: value });
    setSaving(undefined);
  };

  const isAnyFieldEmpty = (student: Student): boolean => {
    for (let key in student) {
      if (student.hasOwnProperty(key)) {
        const value = (student as any)[key];
        if (value === "" || value === 0) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    if (saving !== undefined) {
      return;
    }
    if (isAnyFieldEmpty(student)) {
      console.log("Some Field is Empty", student.completion_date);
    } else {
      setSaving(true);
      const result = await uploadDocument(student);
      if (result) {
        setSaving(false);
      } else {
        setSaving(undefined);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col items-center w-screen h-screen bg-gradient-to-r from-lightGreenBG to-darkGreenBG overflow-y-auto px-6 py-2">
        
        <div className="items-center bg-lightBlue w-full md:w-3/4 h-min flex flex-col pt-6 px-4 gap-4 py-4 rounded-md shadow-lg">
        <img className="h-14 w-14" src={logo} alt="Logo" />
            <div className="text-white">Enter Student Info</div>
          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="degree_number" className="text-white">
                Barcode
              </label>
              <input
                type="text"
                name="barcode"
                value={student.barcode}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Enter Student Barcode #"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="degree_number" className="text-white">
                Degree Number
              </label>
              <input
                type="text"
                name="degree_number"
                value={student.degree_number}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Degree Number"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="reg_number" className="text-white">
                Registration Number
              </label>
              <input
                type="text"
                name="reg_number"
                value={student.reg_number}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Registration Number"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="name" className="text-white">
                First Name
              </label>
              <input
                type="text"
                name="name"
                value={student.name}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="First Name"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="last_name" className="text-white">
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                value={student.last_name}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="cnic" className="text-white">
                CNIC
              </label>
              <input
                type="text"
                name="cnic"
                value={student.cnic}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="CNIC"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="discipline" className="text-white">
                Discipline
              </label>
              <input
                type="text"
                name="discipline"
                value={student.discipline}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Discipline"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="college" className="text-white">
                College
              </label>
              <input
                type="text"
                name="college"
                value={student.college}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="College"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="enrollment_date" className="text-white">
                Enrollment Date
              </label>
              <input
                type="text"
                name="enrollment_date"
                value={student.enrollment_date}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Enrollment Date"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="completion_date" className="text-white">
                Completion Date
              </label>
              <input
                type="text"
                name="completion_date"
                value={student.completion_date}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Completion Date"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="transcript_number" className="text-white">
                Transcript Number
              </label>
              <input
                type="text"
                name="transcript_number"
                value={student.transcript_number}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Transcript Number"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="last_degree" className="text-white">
                Last Degree
              </label>
              <input
                type="text"
                name="last_degree"
                value={student.last_degree}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Last Degree"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="last_degree_reg_num" className="text-white">
                Last Degree Registration Number
              </label>
              <input
                type="text"
                name="last_degree_reg_num"
                value={student.last_degree_reg_num}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Last Degree Reg Number"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="address" className="text-white">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={student.address}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Address"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="contact" className="text-white">
                Contact
              </label>
              <input
                type="text"
                name="contact"
                value={student.contact}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Contact"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="email" className="text-white">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={student.email}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Email"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="examination" className="text-white">
                Examination
              </label>
              <input
                type="text"
                name="examination"
                value={student.examination}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Examination"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="passing_year" className="text-white">
                Passing Year
              </label>
              <input
                type="text"
                name="passing_year"
                value={student.passing_year}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Passing Year"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="session" className="text-white">
                Session
              </label>
              <input
                type="text"
                name="session"
                value={student.session}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Session"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <label htmlFor="total_marks_gpa" className="text-white">
                Total Marks/GPA
              </label>
              <input
                type="text"
                name="total_marks_gpa"
                value={student.total_marks_gpa}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Total Marks/GPA"
              />
            </div>
            <div className="flex-1 w-full">
              <label htmlFor="obtained_marks_gpa" className="text-white">
                Obtained Marks/GPA
              </label>
              <input
                type="text"
                name="obtained_marks_gpa"
                value={student.obtained_marks_gpa}
                onChange={handleChange}
                className="w-full p-1 px-2 rounded-md"
                placeholder="Obtained Marks/GPA"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-6">
            <div className="flex-1 w-full">
              <div
                className="w-full h-10 bg-greenBtnColor rounded-sm text-white text-center flex items-center justify-center cursor-pointer hover:bg-green-600 transition duration-300 ease-in-out shadow-md"
                onClick={handleSubmit}
              >
                {saving === undefined
                  ? "SAVE"
                  : saving === true
                  ? "Saving Data..."
                  : "Saved"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
