import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCYZ0FVdxwUGXzrIo0eryaY88aNvBzy1Io",
    authDomain: "student-verification-por.firebaseapp.com",
    projectId: "student-verification-por",
    storageBucket: "student-verification-por.appspot.com",
    messagingSenderId: "413987804323",
    appId: "1:413987804323:web:5e805ff19b99ecb6c55b16",
    measurementId: "G-CBQEQ8DMDL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);