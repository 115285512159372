import { collection, getDocs } from "firebase/firestore";
import { Student } from "../interfaces/students";
import { db } from "./firebase";
import { studentsCollection } from "./constants";

export const getAllDocuments = async () => {
  try {
    let obj: { students: Student[], ids: string[] } = { students: [], ids: [] };
    const studentsRef = collection(db, studentsCollection);
    const querySnapshot = await getDocs(studentsRef);
    const students: Student[] = [];
    const ids: string[] = [];

    querySnapshot.forEach((doc) => {
      students.push(doc.data() as Student);
      ids.push(doc.id);
    });
    obj.students = students;
    obj.ids = ids;
    return obj;
  } catch (e) {
    console.error("Error fetching student documents: ", e);
    return undefined;
  }
};