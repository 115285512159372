import { collection, getDocs, query, where } from "firebase/firestore";
import { Student } from "../interfaces/students";
import { db } from "./firebase";
import { studentsCollection } from "./constants";

export const getCollectionByField = async (barcode: string): Promise<Student | undefined> => {
    try {
        const studentsRef = collection(db, studentsCollection);
        const q = query(studentsRef, where("barcode", "==", barcode));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const studentDoc = querySnapshot.docs[0];
            const studentData = studentDoc.data() as Student;
            return studentData;
        } else {
            console.log("No matching documents found.");
            return undefined;
        }
    } catch (e) {
        console.error("Error fetching document: ", e);
        return undefined;
    }
};