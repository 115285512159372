import logo from "../assets/logo.png";
import { TableHeading } from "../components/heading.table";
import { TableValue } from "../components/value.table";
import { Student } from "../interfaces/students";

type ResultPageProps = {
  student?: Student;
};
export function ResultPage(props: ResultPageProps) {
  const { student } = props;
  return (
    <div className="flex flex-col items-center w-screen h-screen bg-gradient-to-r from-lightGreenBG to-darkGreenBG overflow-y-auto px-6">
      <a href="/" className="text-darkBlue font-bold text-lg">
        Home
      </a>
      <div className="items-center bg-lightBlue w-full md:w-3/4 h-min flex flex-col pt-6 px-4 gap-2 py-4 rounded-md shadow-lg">
        <div className="w-full text-center flex flex-col items-center">
          <img className="h-14 w-14" src={logo} alt="Logo" />
          <h1 className="text-[#B6D7F3] text-sm md:text-lg">
            Verification System
          </h1>
        </div>
        <h1 className="text-[#EAF9FD] text-sm md:text-lg">View Record</h1>
        <h1 className="text-[#EAF9FD] text-[10px] md:text-sm custom-font">
          {student?.barcode}
        </h1>
        <div className="w-full bg-darkBlue flex flex-col gap-3 py-3 pl-3 rounded-lg">
          {/* Row 1 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading={"Degree #:"} />
            <TableValue value={student?.degree_number} />
            <TableHeading heading="Registration #:" />
            <TableValue value={student?.reg_number} />
          </div>
          {/* Row 2 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Student Name:" />
            <TableValue value={student?.name} />
            <TableHeading heading="Father Name:" />
            <TableValue value={student?.last_name} />
          </div>
          {/* Row 3 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <div className="w-[23%] text-white font-bold text-tiny lg:text-lg">
              CNIC #:
            </div>
            <div className="w-3/4 text-white text-tiny md:text-lg">
              {student?.cnic}
            </div>
          </div>
          {/* College Row */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Discipline:" />
            <TableValue value={student?.discipline} />
            <TableHeading heading="College:" />
            <TableValue value={student?.college} />
          </div>
          {/* Row 4 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Enrollment Date:" />
            <TableValue value={student?.enrollment_date} />
            <TableHeading heading="Completion Date:" />
            <TableValue value={student?.completion_date} />
          </div>
          {/* Row 5 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <div className="w-[23%] text-white font-bold text-tiny lg:text-lg">
              Transcript #:
            </div>
            <div className="w-3/4 text-white text-tiny md:text-lg">
              {student?.transcript_number}
            </div>
          </div>
          {/* Row 6 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Last Degree:" />
            <TableValue value={student?.last_degree} />
            <TableHeading heading="Last Degree Reg #:" />
            <TableValue value={student?.last_degree_reg_num} />
          </div>
          {/* Row 7 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <div className="w-[23%] text-white font-bold text-tiny lg:text-lg">
              Address:
            </div>
            <div className="w-3/4 text-white text-tiny md:text-lg">
              {student?.address}
            </div>
          </div>
          {/* Row 8 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Contact #:" />
            <TableValue value={student?.contact} />
            <TableHeading heading="Email:" />
            <TableValue value={student?.email} />
          </div>
          {/* Row 9  */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Examination:" />
            <TableValue value={student?.examination} />
            <TableHeading heading="Passing Year:" />
            <TableValue value={student?.passing_year} />
          </div>
          {/* Row 10 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <div className="w-[23%] text-white font-bold text-tiny lg:text-lg">
              Session:
            </div>
            <div className="w-3/4 text-white text-tiny md:text-lg">
              {student?.session}
            </div>
          </div>
          {/* Row 11 */}
          <div className="w-full flex flex-grow flex-row justify-between gap-1">
            <TableHeading heading="Total Marks / GPA:" />
            <TableValue value={student?.total_marks_gpa} />
            <TableHeading heading="Obtained Marks / GPA:" />
            <TableValue value={student?.obtained_marks_gpa} />
          </div>
        </div>
      </div>
      <a href="/" className="text-darkBlue font-bold text-lg">
        Home
      </a>
    </div>
  );
}
