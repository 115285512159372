import { addDoc, collection } from "firebase/firestore";
import { Student } from "../interfaces/students";
import { db } from "./firebase";
import { studentsCollection } from "./constants";

export async function uploadDocument(student: Student) {
    try {
        const docRef = await addDoc(collection(db, studentsCollection), student);
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
    } catch (e) {
        console.error("Error adding document: ", e);
        return undefined;
    }
}