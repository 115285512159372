type TableHeadingProps = {
  heading: string;
};
export function TableHeading(props: TableHeadingProps) {
  const { heading } = props;
  return (
    <>
      <div className="flex-1 text-white font-bold text-tiny lg:text-lg">
        {heading}
      </div>
    </>
  );
}
