import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Student } from "./interfaces/students";
import { AddRecord } from "./pages/add.record.page";
import { HomeSearchPage } from "./pages/home.search.screen";
import { RecordInfo } from "./pages/record.info.page";
import { ResultPage } from "./pages/result.page";

function App() {
  const [student, setStudent] = useState<Student | undefined>();
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeSearchPage setStudent={setStudent} />} />
        <Route
          path="/result/:barcode"
          element={<ResultPage student={student} />}
        />
        <Route
          path="/admin/add/akjshdjiashd91/82189192has/dh/ajkdha98129e19/2eh91jkasdhijahs/djkh189291829eh128e9/12hejkhdjkahdk/"
          element={<AddRecord />}
        />
        <Route
          path="/admin/info/akjshdjiashd91/82189192has/dh/ajkdha98129e19/2eh91jkasdhijahs/djkh189291829eh128e9/12hejkhdjkahdk/"
          element={<RecordInfo />}
        />
      </Routes>
    </>
  );
}

export default App;
