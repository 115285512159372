import { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { getAllDocuments } from "../config/get.all.documents";
import { Student } from "../interfaces/students";
import { PINModal } from "../modals/pin.modal";
import { useNavigate } from "react-router-dom";

export function RecordInfo() {
  const [students, setStudents] = useState<Student[]>([]);
  const [docIDs, setDocIDs] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllStudents = async () => {
      let result = await getAllDocuments();
      if (result) {
        setStudents(result.students);
        setDocIDs(result.ids);
      }
    };

    fetchAllStudents();
  }, []);

  return (
    <>
      <div className="flex flex-col items-center w-screen h-screen bg-gradient-to-r from-lightGreenBG to-darkGreenBG overflow-y-auto px-6 py-2">

        <div className="items-center bg-lightBlue w-full md:w-3/4 h-min flex flex-col pt-6 px-4 gap-4 py-4 rounded-md shadow-lg">
          <div className="w-full text-center flex flex-col items-center">
            <img className="h-14 w-14" src={logo} alt="Logo" />
            <h1 className="text-[#B6D7F3] text-sm md:text-lg">
              Verification System
            </h1>
          </div>
          <h1 className="text-[#EAF9FD] text-sm md:text-lg">Record Inf<span onClick={()=>navigate(`/admin/add/akjshdjiashd91/82189192has/dh/ajkdha98129e19/2eh91jkasdhijahs/djkh189291829eh128e9/12hejkhdjkahdk/`)}>o</span></h1>

          <div className="w-full bg-darkBlue flex flex-col gap-3 py-3 px-3 rounded-lg overflow-y-auto">
            <div className="flex flex-row w-full px-2">
              <div className="flex-1 text-white font-bold">Record ID</div>
              <div className="flex-1 text-white font-bold">Reg #</div>
              <div className="flex-1 text-white font-bold">Full Name</div>
            </div>
            <hr />
            <div className="max-h-[50vh] w-full bg-darkBlue flex flex-col gap-3 py-3 px-3 rounded-lg overflow-y-auto">
              {students.map((s, i) => (
                <>
                  <div className="flex flex-row w-full gap-3" key={docIDs[i]}>
                    <div className="flex-1 text-white overflow-x-auto">
                      {docIDs[i]}
                    </div>
                    <div className="flex-1 text-white">{s.reg_number}</div>
                    <div className="flex-1 text-white">
                      {s.name + " " + s.last_name}
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
