import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { getCollectionByField } from "../config/get.document";
import { Student } from "../interfaces/students";
import { PINModal } from "../modals/pin.modal";

type HomePagePros = {
  setStudent: React.Dispatch<React.SetStateAction<Student | undefined>>;
};

export function HomeSearchPage(props: HomePagePros) {
  const [searchTxt, setSearchTxt] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const { setStudent } = props;
  const handleSearch = async () => {
    if (isLoading === true) {
      return;
    }
    if (searchTxt === "") {
      setShowError(true);
      return;
    }
    setIsLoading(true);
    const result = await getCollectionByField(searchTxt);
    setIsLoading(false);
    if (result !== undefined) {
      setStudent(result);
      navigate(`/result/${result.barcode}`);
    } else {
      setShowNotFound(true);
    }
  };

  return (
    <div className="flex justify-center w-screen h-screen bg-gradient-to-r from-lightGreenBG to-darkGreenBG py-6 px-8">
      <div className="items-center bg-lightBlue w-full md:w-2/4 h-min flex flex-col pt-6 px-4 gap-2 py-4 rounded-md shadow-lg">
      {open && <PINModal open={open} handleClose={handleClose} />}
        <div className="w-full text-center flex flex-col items-center">
          <img className="h-14 w-14" src={logo} alt="Logo" />
          <h1 className="text-[#B6D7F3] text-sm md:text-lg">
            Verification Syste<span onClick={handleOpen}>m</span>
          </h1>
        </div>
        <h1 className="text-[#EAF9FD] text-sm md:text-lg">
          Search Student Record
        </h1>
        <div className="w-full text-center">
          <h1 className="text-[#B6D7F3]">Barcode #</h1>
          <input
            className="h-10 text-xs md:text-lg w-full border-gray-300 focus:border-red-500 focus:ring-red-500 focus:outline-none rounded-sm px-3 py-2"
            type="text"
            name="barcode"
            id="txtBarcode"
            placeholder="Enter Barcode #"
            value={searchTxt}
            onChange={(e) => {
              setSearchTxt(e.target.value);
              if (showError === true) {
                setShowError(false);
              }
              if (showNotFound === true) {
                setShowNotFound(false);
              }
            }}
          />
          <div className="w-full h-6 text-start text-red-400">
            {showError && "Please Enter Barcode"}
            {showNotFound && "Record Not Found, Try Again"}
          </div>
        </div>

        <div
          className="w-full h-10 bg-greenBtnColor rounded-sm text-white text-center flex items-center justify-center cursor-pointer hover:bg-green-600 transition duration-300 ease-in-out shadow-md"
          onClick={handleSearch}
        >
          {isLoading ? "Searching..." : "SEARCH"}
        </div>
        <Link to={"/result"} className="w-full"></Link>
      </div>
    </div>
  );
}
