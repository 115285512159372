import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
  } from "@mui/material";
  import {  useState } from "react";
  import PinInput from "react-pin-input";
  import { useNavigate } from "react-router-dom";
  
  type PINModalProps = {
    open: boolean;
    handleClose: () => void;
  };
  
  export function PINModal(props: PINModalProps) {
    const { handleClose, open } = props;
    const [error, setError] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
  
    const navigate = useNavigate();
  
    const verifyPIN = () => {
      if (value === '3347') {
        navigate("/admin/info/akjshdjiashd91/82189192has/dh/ajkdha98129e19/2eh91jkasdhijahs/djkh189291829eh128e9/12hejkhdjkahdk/");
      } else {
        handleClose();
      }
    };
  
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Verify PIN"}</DialogTitle>
          <DialogContent>
            <PinInput
              length={4}
              initialValue=""
              secret
              secretDelay={100}
              onChange={(v, index) => {
                setValue(v);
                if (error) {
                  setError(false);
                }
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "red" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
  
            {error && (
              <Typography sx={{ mt: 1 }} color={"red"}>
                invalid PIN*
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={verifyPIN}
              fullWidth
            >
              Verify
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  